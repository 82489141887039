
<template>
  <div class="vpnAccess">
    <h1>Téléchargement du certificat VPN</h1>
    <v-alert
      text
      color="red"
      type="error"
      v-show="(error !== null)"
    >{{ error }}</v-alert>
    <codemirror v-model="code" :options="cmOption" class="code-snippet" />
  </div>
</template>

<script>
import { codemirror } from 'vue-codemirror'

import 'codemirror/theme/cobalt.css'
import '../assets/scss/vpnAccess.scss'
import 'codemirror/addon/selection/active-line.js'
import axios from 'axios'

export default {
  components: {
    codemirror
  },
  data () {
    return {
      code: null,
      error: null,
      cmOption: {
        tabSize: 2,
        styleActiveLine: true,
        lineNumbers: true,
        line: true,
        lineWrapping: true,
        theme: 'idea',
        readOnly: true,
        highlightDifferences: true
      }
    }
  },
  mounted () {
    const token = localStorage.getItem('tokenData')
    if (token === null) {
      this.$router.push('/login')
    } else {
      axios.get(process.env.VUE_APP_ROOT_API + '/vpn/configuration', {
        headers: { authorization: 'Bearer ' + token }
      }).then(response => {
        console.log('[+] Getting vpn configuration')
        this.code = response.data
      }).catch(error => {
        if (error.response !== undefined && error.response.data.message !== undefined) {
          this.error = error.response.data.message
        } else {
          this.error = error
        }
      })
    }
  }
}
</script>
